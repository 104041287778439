import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../Pages/Newhomepage.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import banphone from '../Pages/Newimages/banner-phone.png'
import sidephone from '../Pages/Newimages/sidephone.png'

// import flip from '../Pages/Newimages/flipkart.png'
import aws from '../Pages/Newimages/aws.png'
import zoho from '../Pages/Newimages/zoho.png'
// import amazon from '../Pages/Newimages/amazon.png'
import surya from '../Pages/Newimages/surya.png'
import csb from '../Pages/Newimages/csb.png'
import digital from '../Pages/Newimages/digital.png'
import axis from '../Pages/Newimages/axis.png'
import open from '../Pages/Newimages/open.png'



import stat from '../Pages/Newimages/stat.png'
import time from '../Pages/Newimages/time.png'
import circle from '../Pages/Newimages/circle.png'
import thunder from '../Pages/Newimages/thunder.png'
import empowering from '../Pages/Newimages/empowering.png'

import lap from '../Pages/Newimages/laptop.png'

import playbtn from '../Pages/Newimages/playbtn.png'
import applebtn from '../Pages/Newimages/applebtn.png'
import app from '../Pages/Newimages/app.png'

import Newnavbar from '../Pages/Newnavbar'
import Newfooter from './Newfooter';






const NewHomepage = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: 'ease-in-out',
            once: false,
        });
    }, []);
    return (
        <div className="new_ui_all">
            <Newnavbar/>
            <div className="banner_img py-5">
                <div className="row container mx-auto py-5 ">
                    <div className="col-md-8">
                        <div className="new_ban_des">
                            <h2>Effortless Financial Management with <span className="ban_span" >Paybi</span> </h2>
                            <p>Manage your business finances effortlessly with our smart expense management suite. Simplify approvals, track real-time expenses, and access insightful reports all in one secure platform designed for Indian businesses to thrive.</p>
                            <button type="button">Get Started <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                <path d="M11.666 19.8334L17.4993 14L11.666 8.16669" stroke="black" stroke-width="2.33377" stroke-linecap="round" stroke-linejoin="round" />
                            </svg></button>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <img src={banphone} alt="Banner Phone" className="img-fluid"   data-aos="zoom-in-up"     />

                    </div>

                </div>
            </div>
            <div className="trust_companies">
                {/* <h2>Trusted by Worldwide Business Partners</h2> */}
                <h2>Recognized by Prestigious Institutions Worldwide
                </h2>
                <div className="com_img_all marquee">
                    <img src={zoho} alt="Banner Phone" className="img-fluid" />
                    <img src={aws} alt="Banner Phone" className="img-fluid" />
                    <img src={axis} alt="Banner Phone" className="img-fluid" />
                    <img src={digital} alt="Banner Phone" className="img-fluid" />
                    <img src={csb} alt="Banner Phone" className="img-fluid" />
                    <img src={open} alt="Banner Phone" className="img-fluid" />
                    <img src={surya} alt="Banner Phone" className="img-fluid" />
                    <img src={open} alt="Banner Phone" className="img-fluid" />

                    



                </div>
            </div>


            <div className="trust_below_des py-5">
                <div className="row container mx-auto">
                    <div className="col-md-6 col-12">
                        <p>
                            ARE YOU STILL CONFUSED <br />
                            ABOUT YOUR PAYMENTS AND <br />
                            EXPENSES?
                        </p>
                    </div>
                    <div className="col-md-6 col-12  d-flex justify-content-center align-items-center">
                        <h2>
                            Smart Expense Management, <br />
                            Payment Solutions for All   <br />
                            Type of Business.


                        </h2>
                    </div>
                </div>
            </div>


            <div className="iphone md-py-5 py-0">
                <div className="row container g-0 mx-auto">
                    <div className="col-md-4 col-12 pe-0 me-0">
                        <img src={sidephone} alt="Edge" className="img-fluid float-end pe-3" data-aos="zoom-in-up"  />
                    </div>
                    <div className="col-md-8 col-12">
                        <div className="iphone-des container md-mt-0 mt-3">
                            {/* <p className="service-des " >
                  <span>Edge Helps </span> The Problem You Are Facing
                </p> */}
                            <div className="i-card">
                                <div className="num">
                                    <span>1</span>
                                </div>
                                <div className="i-card-des">
                                    <h2>Handle High - Volume Transactions with Ease</h2>
                                    <p>
                                        Efficiently manage large-scale transactions without any hassle. Our solution ensures speed, accuracy, and reliability for seamless operations.
                                    </p>
                                </div>
                                <div className="info">
                                    {/* <IoIosInformationCircleOutline /> */}
                                    {/* <img src={frame} alt="Edge" className="img-fluid" /> */}

                                </div>
                            </div>
                            <div className="i-card">
                                <div className="num">
                                    <span>2</span>
                                </div>
                                <div className="i-card-des">
                                    <h2>Advanced Security - Protecting data and payments</h2>
                                    <p>
                                        Ensure the safety of your data and transactions with cutting-edge security measures. Our advanced protection safeguards your business against threats.
                                    </p>
                                </div>
                                <div className="info">
                                    {/* <img src={frame} alt="Edge" className="img-fluid" /> */}
                                </div>
                            </div>
                            <div className="i-card">
                                <div className="num">
                                    <span>3</span>
                                </div>
                                <div className="i-card-des">
                                    <h2>Expand your business worldwide</h2>
                                    <p>
                                        Take your business global with seamless scalability and reach. Unlock new markets and connect with customers across the world effortlessly
                                    </p>
                                </div>
                                <div className="info">
                                    {/* <img src={frame} alt="Edge" className="img-fluid" /> */}
                                </div>
                            </div>
                            <div className="i-card">
                                <div className="num">
                                    <span>4</span>
                                </div>
                                <div className="i-card-des">
                                    <h2>Customizable Integration - Tailored to your business needs</h2>
                                    <p>
                                        Adapt solutions to match your unique business requirements. Our customizable integrations ensure a perfect fit for your operations.
                                    </p>
                                </div>
                                <div className="info">
                                    {/* <img src={frame} alt="Edge" className="img-fluid" /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="half-iphone py-5">
                <div className="row container mx-auto">
                    <div className="col-md-7 col-12 order-2 order-md-1">
                        <div className="half-i-des">
                            <h2>
                                Empowering Your Business with <br /> Tailored Solutions
                            </h2>
                            <div className="transactions">
                                <div className="transaction-col">
                                    <img src={time} alt="Edge" className="img-fluid" />
                                    <h3>Real-Time Expense Tracking </h3>
                                    <p>
                                    Enjoy seamless, secure, and reliable payments  trusted by <br /> businesses for  fast transactions anywhere.
                                    </p>
                                </div>
                                <div className="transaction-col">
                                    <img src={thunder} alt="Edge" className="img-fluid" />
                                    <h3>Fast & Seamless Payments</h3>
                                    <p>
                                    Experience seamless, secure payments anytime,<br /> anywhere, with trusted speed and reliability in every transaction.
                                    </p>
                                </div>
                            </div>
                            <div className="transactions py-3">
                                <div className="transaction-col">
                                    <img src={circle} alt="Edge" className="img-fluid" />
                                    <h3>Categorized Expenses</h3>
                                    <p>
                                    Effortlessly track and categorize your <br /> spending with PayBi for smarter financial decisions.
                                    </p>
                                </div>
                                <div className="transaction-col">
                                    {/* Uncomment and ensure FaHandHoldingHeart is imported */}
                                    {/* <FaHandHoldingHeart className="globe-h" /> */}
                                    <img src={stat} alt="Edge" className="img-fluid" />
                                    <h3>Detailed Spending Reports</h3>
                                    <p>
                                    Gain detailed spending insights with PayBi’s <br /> reports to track trends, set goals, and identify savings.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 col-12 order-1 order-md-2 mt-5">
                        <img src={empowering} alt="Edge" className="img-fluid" data-aos="zoom-in-up"  />

                    </div>
                </div>
            </div>



            <div className="lap-sec">
          <h1>
          Stay Ahead with PayBi on Our <br /> Web or Mobile App
                    </h1>
          <div className="lap-img container mx-auto">
            <img src={lap} alt="Edge" className="img-fluid" data-aos="zoom-in-up"  />
          </div>
          <div className="peoples pb-5 mb-5">
            <div className="row container mx-auto">
              <div className="col-md-4 col-12">
                <div className="peoples-des">
                  <h3>32k</h3>
                  <h2>More than 32k people use PayBi</h2>
                  <p>
                  More than 32k people have used our services, many have used PayBi
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="peoples-des">
                  <h3>5k</h3>
                  <h2>5k people Use the app</h2>
                  <p>
                  more than 5K people download the app and try it. You have to try
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="peoples-des">
                  <h3>10k+</h3>
                  <h2>10k+ people helped</h2>
                  <p>
                  10k+ people have been helped by Edge's services, we are ready to help you
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="mobile-app md-py-5 md-mt-5 py-2 mt-2 ">
            <div className="row mobile-shade container mx-auto mb-5">
           

              <div className="col-md-6 col-12">
              <div className="soon-all">
                <p>
                Stay Ahead with PayBi's <br /> Latest Upgrade
                                </p>
                <div className="soon">
                <img src={playbtn} alt="Edge" className="img-fluid md-mb-0 mb-3" />


                  <img src={applebtn} alt="Edge" className="img-fluid md-mb-0 mb-3" />

                </div>
                </div>

              </div>
              <div className="col-md-6 col-12">
                <div className="app-imgg">
                <img src={app} alt="Edge" className="img-fluid" data-aos="zoom-in-up"  />
                </div>
               
              </div>
            </div>
          </div>
        </div>

<Newfooter/>

        </div>
    )
}

export default NewHomepage
